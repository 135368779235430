
import TEXT from './ENGAddress.json'
import schoolName from './schoolName.json'
import data_translate from './translate/translate'

let ENG_Addrees = (text, translate = 'TH') => {

    if (translate === 'TH') {
        if (data_translate.TH[text] || data_translate.TH[text] === '') return data_translate.TH[text]
        else return text
    } else {
        if (data_translate.EN[text] || data_translate.EN[text] === '') return data_translate.EN[text]
        else if (TEXT[text]) return TEXT[text]
        else if (schoolName[text]) return schoolName[text]
        else return text
    }
}
export default ENG_Addrees 